/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { useHistory } from "react-router-dom";

import { Config } from "../../Constants";

import EquipmentComponent from "./EquipmentComponent";

import IEquipmentOption from "../../models/interfaces/IEquipmentOption";
import IEquipmentUnitAttributes from "../../models/interfaces/IEquipmentUnitAttributes";
import IEquipmentUnitItem from "../../models/interfaces/IEquipmentUnitItem";
import IMessage from "../../models/interfaces/IMessage";
import ISystem from "../../models/interfaces/ISystem";
import ISystemBalance from "../../models/interfaces/ISystemBalance";

import LoadCalculationService from "../../services/LoadCalculationService";

import useErrorContext from "../../hooks/useErrorContext";
import useLoadingContext from "../../hooks/useLoadingContext";
import useSystemContext from "../../hooks/useSystemContext";
import useSystemState from "../../hooks/useSystemState";
import useTranslationContext from "../../hooks/useTranslationContext";
import StorageFacade from "../../services/StorageFacade";
import { refrigerants } from "../EquipmentFilters/EquipmentFilters";
import ConfirmationMessage from "../helpers/ConfirmationMessage";

interface EquipmentProps {}

const Equipment: React.FunctionComponent<EquipmentProps> = () => {
	
	const history = useHistory();
	const [showConfirmation, setShowConfirmation] = React.useState(false);
	const { showError, showErrorList, errorMessages } = useErrorContext();
	const { showLoading, hideLoading } = useLoadingContext();
	const { systemState, dispatch } = useSystemContext();
	const { translations } = useTranslationContext();

	const { validateEquipmentSelector } = useSystemState();

	React.useEffect(() => {
		if (!systemState.store.boxApplicationId) {
			showError({
                name: "Application",
                text: "Application is not selected properly."
			});
		}
	}, []);

	const emptySubmitConfirmationMessage = {
		title: "Confirmation",
		message: <div>Are you sure you want to continue without equipment selection?</div>,
		onYesClick: () => {
            setShowConfirmation(false);
            submitChanges();
        },
		onNoClick: () => {
            setShowConfirmation(false);
        }
    };

	const toggleModal = () => {
		// setState({
		// 	...state,
		// 	modalState: !state.modalState
		// });
	};

	const showErrors = (
        errors: IMessage[],
        currentFieldError?: IMessage
    ) => {
        let newList = [] as IMessage[];
        
        const currentList = [...errorMessages];
        currentList.forEach(c => {
            const errorText = errors.find(e => e.name === c.name)?.text ?? "";
            newList.push({
                name: c.name,
                text: errorText
            });
        });

        if (currentFieldError) {
            if (currentList.find(c => c.name === currentFieldError.name)) {
                newList = newList.map(n => {
                    return {
                        name: n.name,
                        text: (n.name === currentFieldError.name) ? currentFieldError.text : n.text
                    }
                });
            }
            else {
                newList.push(currentFieldError);
            }
        }
    
        showErrorList(newList, false);
    };

    const validateField = (
		system: ISystem,
        fieldName: string
	) => {

        const errors = validateEquipmentSelector(system);

        const fieldError: IMessage = {
            name: fieldName,
            text: errors.find(e => e.name === fieldName)?.text ?? ""
        };
        
        showErrors(errors, fieldError);
        
        return fieldError.text;
	};

	const validateSystemField = (
        fieldName: string,
        fieldValue: any
	) => {

        const system = {
			...systemState.formState.actualValues,
			[fieldName]: fieldValue
        };

        return validateField(system, fieldName);
    };
	
	const validateEquipmentField = (
        fieldName: string,
        fieldValue: any
	) => {

        const system = {
			...systemState.formState.actualValues,
			equipmentSelection: {
				...systemState.formState.actualValues.equipmentSelection,
				[fieldName]: fieldValue
			}
        };

        return validateField(system, fieldName);
    };

	const handleClearSelections = () => {
		dispatch({ 
			type: "clear-equipment-units", 
			updateStore: false
		});
	};

	const handleDropDownChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const fieldName = event.currentTarget.name;
		const fieldValue = event.currentTarget.value;

		dispatch({ 
			type: "change-equipment-value", 
			fieldName, 
			fieldValue,
			updateStore: false
		});
	};

	const handleCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
        const fieldName = event.currentTarget.name;
        const isChecked = event.currentTarget.checked;

        dispatch({ 
			type: "change-value", 
			fieldName, 
			fieldValue: isChecked,
			updateStore: false
		});
    };

	const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		const fieldName = event.currentTarget.name;
		const fieldValue = event.currentTarget.value;

		dispatch({ 
			type: "change-equipment-value", 
			fieldName, 
			fieldValue,
			updateStore: false
		});
	};

	const handleMeasurementChange = (fieldName: string, fieldValue: any) => {
		switch (fieldName) {
			case "interiorTemperature":
			case "safetyFactor":
			case "runTimeHours":
			case "totalHourlyLoad":
			case "totalLoadRequired":
			case "capacityRequired":
				dispatch({ 
					type: "customize-property",
					fieldName, 
					fieldValue,
					updateStore: false
				});
				break;
			default:
				dispatch({ 
					type: "customize-equipment-property", 
					fieldName, 
					fieldValue,
					updateStore: false
				});
				break;
		}
		
	};

	const handleCondensorEquipmentSelected = (equipment: IEquipmentUnitItem, selectedOptions: IEquipmentOption[] | null) => {
		showError({
			name: "Model Selector",
			text: ""
		});

		dispatch({ 
			type: "set-selected-condenser",
			equipment,
			selectedOptions,
			updateStore: false
		});
	};

	const handleEvaporatorEquipmentSelected = (equipment: IEquipmentUnitItem, selectedOptions: IEquipmentOption[] | null) => {
		dispatch({ 
			type: "set-selected-evaporator", 	
			equipment,
			selectedOptions,
			updateStore: false
		});
	};

	const handleCondenserUnitOptionsSelected = (options: IEquipmentOption[]) => {
		dispatch({ 
			type: "change-equipment-value", 
			fieldName: "selectedCondenserOptionList", 
			fieldValue: options,
			updateStore: false
		});
	};

	const handleEvaporatorOptionsSelected = (options: IEquipmentOption[]) => {
		dispatch({ 
			type: "change-equipment-value", 
			fieldName: "selectedEvaporatorOptionList", 
			fieldValue: options,
			updateStore: false
		});
	};

	const handleCondenserAttributeChanged = (
		attributes: IEquipmentUnitAttributes
	) => {
		if (attributes) {
			dispatch({ 
				type: "change-equipment-value", 
				fieldName: "selectedCondenserAttributes", 
				fieldValue: attributes,
				updateStore: false
			});
		}
	};

	const handleEvaporatorAttributeChanged = (
		attributes: IEquipmentUnitAttributes
	) => {
		if (attributes) {
			dispatch({ 
				type: "change-equipment-value", 
				fieldName: "selectedEvaporatorAttributes", 
				fieldValue: attributes,
				updateStore: false
			});
		}
	};

	const calculateSystemBalance = () => {
		const currentSystem = systemState.formState.actualValues;
		if (
			currentSystem.equipmentSelection.selectedCondenserUnit?.class9ID != null &&
			currentSystem.equipmentSelection.selectedEvaporator?.class9ID != null
		) {
			const getUrl = `${Config.API_URL}/equipment/balance`;

			const getParams: any = {
				EqType: currentSystem.equipmentSelection.equipmentType,
				Fluid: currentSystem.equipmentSelection.refrigerant,
				InTemp: currentSystem.interiorTemperature,
				SucLoss: currentSystem.equipmentSelection.suctionLineLoss,
				AmbTemp: currentSystem.equipmentSelection.condensingUnitAmbientTemperature,
				ConTemp: currentSystem.equipmentSelection.condensingTemperature,
				ConC9Id: currentSystem.equipmentSelection.selectedCondenserUnit.class9ID,
				EvapC9Id: currentSystem.equipmentSelection.selectedEvaporator.class9ID,
				NoCon: currentSystem.equipmentSelection.condenserUnitCount,
				NoEvap: currentSystem.equipmentSelection.evaporatorUnitCount,
				ConOps: "",
				EvapOps: ""
			};

			const queryString = Object.keys(getParams)
				.filter(key => getParams[key] !== null && getParams[key] !== undefined)
				.map(key => `${key}=${getParams[key]}`)
				.join("&");

			fetch(`${getUrl}?${queryString}`)
				.then(response => response.text())
				.then(text => {
					const data: ISystemBalance = text.length ? JSON.parse(text) : {};
					if (data.balanced) {
						dispatch({ 
							type: "set-equipment-balance", 	
							systemBalanced: true,
							sstBalance: data.sst,
							tdBalance: data.td,
							totalSystemCapacity: data.capacity,
							updateStore: false
						});
					} else {
						dispatch({ 
							type: "set-equipment-balance", 	
							systemBalanced: false,
							sstBalance: "",
							tdBalance: "",
							totalSystemCapacity: "",
							updateStore: false
						});
					}
				})
				.catch(error => {
					dispatch({ 
						type: "set-equipment-balance", 	
						systemBalanced: true,
						sstBalance: "",
						tdBalance: "",
						totalSystemCapacity: "",
						updateStore: false
					});

					console.log(error);
				});
		}
		else {
			dispatch({ 
				type: "set-equipment-balance", 	
				systemBalanced: true,
				sstBalance: "",
				tdBalance: "",
				totalSystemCapacity: "",
				updateStore: false
			});
		}
	};

	React.useEffect(() => {
		if (systemState.formState.actualValues.equipmentSelection.isMatchedReloaded) {
			calculateSystemBalance();
		}
	}, [
		systemState.formState.actualValues.equipmentSelection.isMatchedReloaded
	]);

	React.useEffect(() => {

        const getLoads = async () => {
            const loads = await LoadCalculationService.fetchCalculatedLoadsAsync(
                systemState.formState.actualValues.boxApplicationId ?? 0,
                systemState.formState.actualValues.boxDesign.floorArea,
                systemState.formState.actualValues.boxDesign.volume,
                systemState.formState.actualValues.boxDesign.totalInfiltrationLoad,
                systemState.formState.actualValues.exteriorTemperature,
				systemState.formState.actualValues.exteriorHumidity,
				systemState.formState.actualValues.interiorTemperature,
                systemState.formState.actualValues.interiorHumidity,
                systemState.store.miscLoad,
                systemState.store.infiltrationLoad,
                systemState.store.productLoad);

            const {totalMiscLoad, totalInfiltrationLoad, totalProductLoad, miscLoadItems, infiltrationLoadItems, productLoadItems} = loads;

            dispatch({ 
				type: "recalculate-loads", 
                totalMiscLoad: totalMiscLoad > 0 ? totalMiscLoad : undefined, 
                totalInfiltrationLoad: totalInfiltrationLoad > 0 ? totalInfiltrationLoad : undefined, 
				totalProductLoad: totalProductLoad > 0 ? totalProductLoad : undefined,
				miscLoadItems,
				infiltrationLoadItems,
				productLoadItems,
				updateStore: false
            });

            hideLoading();
        };

		if (systemState.formState.actualValues.boxApplicationId
			&& systemState.formState.actualValues.boxApplicationId > 0
		) {
            showLoading();
            getLoads();
        }
	}, [
		systemState.formState.actualValues.boxApplicationId,
		systemState.formState.actualValues.boxDesign.floorArea,
		systemState.formState.actualValues.boxDesign.volume,
		systemState.formState.actualValues.boxDesign.totalInfiltrationLoad,
		systemState.formState.actualValues.exteriorTemperature,
		systemState.formState.actualValues.exteriorHumidity,
		systemState.formState.actualValues.interiorTemperature,
		systemState.formState.actualValues.interiorHumidity,
		systemState.store.miscLoad,
		systemState.store.infiltrationLoad,
		systemState.store.productLoad
    ]);

	const handleShowSystemChargeButtonClick = () => {
		const division = systemState.formState.actualValues.equipmentSelection.divisionId?.toString();
		const refrigerant = refrigerants.find(a => a.key === systemState.formState.actualValues.equipmentSelection.refrigerant?.toString())?.value;
		const condenser = systemState.formState.actualValues.equipmentSelection.selectedCondenserUnit?.class9ID?.toString();
		const evaporator = systemState.formState.actualValues.equipmentSelection.selectedEvaporator?.class9ID?.toString();
		const evaporatorCount = systemState.formState.actualValues.equipmentSelection.evaporatorUnitCount?.toString();

		const params: string[] = [];
		if (division) {
			params.push(`div=${division}`)
		}
		if (refrigerant) {
			params.push(`ref=${refrigerant}`)
		}
		if (condenser) {
			params.push(`cond=${condenser}`)
		}
		if (evaporator) {
			params.push(`evap=${evaporator}`)
		}
		if (evaporatorCount) {
			params.push(`evapqty=${evaporatorCount}`)
		}

		let querystring = ""
		if (params.length > 0) {
			querystring = `&${params.join("&")}`;
		}

        window.open(`/scc?loadcalc=1${querystring}`, '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
	}

	const handlePrintPreviewClick = () => {
		if (!systemState.formState.actualValues.equipmentSelection.selectedCondenserUnit && !systemState.formState.actualValues.equipmentSelection.selectedEvaporator) {
			showError({
				name: "Model Selector",
				text: translations.equipmentSelectionIsIncomplete_
			});
			return;
        }		
        
        StorageFacade.transferLoadCalcSystemFromSessionToLocal();
        StorageFacade.transferProjectFromSessionToLocal();

        window.open("/printequipment", '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
	};

	const handleSubmitClick = () => {
		if (!systemState.formState.actualValues.equipmentSelection.selectedCondenserUnit && !systemState.formState.actualValues.equipmentSelection.selectedEvaporator) {
			setShowConfirmation(true);
			return;
		}

		submitChanges();
	};

	const handleCancelClick = () => {
		dispatch({ 
            type: "cancel-changes"
        });
	};

	const submitChanges = () => {
		dispatch({ 
            type: "submit-changes", 
            updateStore: true
		});
	};

	React.useEffect(() => {
		if (systemState.action === "submit-changes") {
			if (systemState.store.equipmentSelection.equipmentOnly) {
				if (systemState.store.isEditing) {
					window.location.href = "/project/edit";
				}
				else {
					window.location.href = "/project/add";
				}
			}
			else {
				history.goBack();
			}
		}
		else if (systemState.action === "cancel-changes") {
			if (systemState.store.equipmentSelection.equipmentOnly) {
				window.location.href = "/project/cancel";
			}
			else {
				history.goBack();
			}
		}
	}, [systemState.action]);

	return (
		<>
			<EquipmentComponent 
				isEquipmentOnly={systemState.store.equipmentSelection.equipmentOnly}
				formState={systemState.formState}
				onInputChange={handleInputChange}
				onDropDownChange={handleDropDownChange}
				onCheckboxChange={handleCheckboxChange}
				onMeasurementChange={handleMeasurementChange}
				onValidateSystemField={validateSystemField}
				onValidateEquipmentField={validateEquipmentField}
				onCondensorEquipmentSelected={handleCondensorEquipmentSelected}
				onEvaporatorEquipmentSelected={handleEvaporatorEquipmentSelected}
				onCondenserUnitOptionsSelected={handleCondenserUnitOptionsSelected}
				onEvaporatorOptionsSelected={handleEvaporatorOptionsSelected}
				onCondenserAttributeChanged={handleCondenserAttributeChanged}
				onEvaporatorAttributeChanged={handleEvaporatorAttributeChanged}
				onToggleModal={toggleModal}
				onClearSelections={handleClearSelections}
				onShowSystemChargeButtonClick={handleShowSystemChargeButtonClick}
				onPrintPreviewClick={handlePrintPreviewClick}
				onSubmitEquipmentClick={handleSubmitClick}
				onCancelEquipmentClick={handleCancelClick}
			/>
			<ConfirmationMessage
                title={emptySubmitConfirmationMessage.title}
                message={emptySubmitConfirmationMessage.message}
                onYesClick={emptySubmitConfirmationMessage.onYesClick}
                onNoClick={emptySubmitConfirmationMessage.onNoClick}
                onCancelClick={emptySubmitConfirmationMessage.onNoClick}
                show={showConfirmation}
            />
		</>
	);
}

export default Equipment;
