import * as React from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import StorageFacade from "../../services/StorageFacade";
import LoadCalculationService from "../../services/LoadCalculationService";

import IEquipmentState from "../../models/interfaces/IEquipmentState";

import LoadInfoEquipmentSummary from "./LoadInfoEquipmentSummary";
import ModelInfo from "./ModelInfo";
import ModelOptions from "./ModelOptions";
import ModelDrawing from "./ModelDrawing";

import useLoadingContext from "../../hooks/useLoadingContext";
import useSystemContext from "../../hooks/useSystemContext";

import "./Print.css";
import HelperService from "../../services/HelperService";

function hideElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) element.style.display = "none";
}

function showElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) element.style.display = "block";
}

const PrintSingleReport = () => {
    const [exportingPageNumber, setExportingPageNumber] = React.useState(0);
    const [totalPageNumber, setTotalPageNumber] = React.useState(0);
    const [isFromLegacy, setIsFromLegacy] = React.useState(false);

    const { showLoading, hideLoading } = useLoadingContext();
    const { systemState, dispatch } = useSystemContext();

    const pagesEquipment: Array<string> = [];

    React.useEffect(() => {
        setIsFromLegacy(StorageFacade.isFromLegacy);

        document.title = "Print Preview"
    }, []);

    function addPDFPage(
        pdf: any,
        currentPageIndex: number
    ) {
        if (currentPageIndex >= pagesEquipment.length) {
            pdf.save("certified_print.pdf"); // Generated PDF												

            showElement("ModelInfo");
            showElement("ModelDrawing");

            setExportingPageNumber(-1);
            // setTimeout(() => { setExportingPageNumber(0); }, 8000)

            hideLoading();

            return;
        }

        if (currentPageIndex === 1) {
            hideElement("ModelInfo");
        }
        else if (currentPageIndex > 1) {
            hideElement("ModelDrawing");
        }

        pdf.addPage();
        const dataPage2: any = document.getElementById(pagesEquipment[currentPageIndex]);

        window.scrollTo(0, 0);
        setExportingPageNumber(currentPageIndex + 1);
        html2canvas(dataPage2, {
            scale: 1.25,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY
        }).then(canvas2 => {
            const imgWidth = 420;
            const imgHeight = (canvas2.height * imgWidth) / canvas2.width;

            pdf.addImage(canvas2, "PNG", 12, 15, imgWidth, imgHeight, undefined, "FAST");

            addPDFPage(pdf, currentPageIndex + 1);
        });
    }

    function exportPDF(equipmentInfo: IEquipmentState | null) {
        if (!equipmentInfo) {
            return;
        }

        showLoading();

        pagesEquipment.length = 0;
        pagesEquipment.push("ModelInfo");

        /*if (equipmentInfo?.selectedCondenserOptionList
            && equipmentInfo?.selectedCondenserOptionList.length > 0
        ) {
            pagesEquipment.push("ModelOptions");
        }*/

        pagesEquipment.push("ModelDrawing");
        setTotalPageNumber(pagesEquipment.length);

        const dataPage1: any = document.getElementById("ModelInfo");
        window.scrollTo(0, 0);
        setExportingPageNumber(1);
        html2canvas(dataPage1, {
            scale: 1.25,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY
        }).then(canvas1 => {
            // Few necessary setting options
            const imgWidth = 420;
            const imgHeight = (canvas1.height * imgWidth) / canvas1.width;

            const pdf = new jsPDF({
                unit: "px",
                compressPdf: true
            }); // A4 size page of PDF

            pdf.addImage(canvas1, "PNG", 12, 15, imgWidth, imgHeight, undefined, "FAST");

            addPDFPage(pdf, 1);
        });
    }

    //const equipmentInfo = systemState.formState.actualValues.equipmentSelection;
    const divisionId = Number(StorageFacade.divisionId);
    const equipmentToPrint = StorageFacade.EquipmentToPrint;
    const cpmFormInput = StorageFacade.CPMFormInput;
    const cpmQuoteFormInput = StorageFacade.CPMQuoteFormInput;
    const options = StorageFacade.EquipmentToPrint_Options;
    const capacityError = StorageFacade.EquipmentToPrint_CapacityHasError;
    const equipmentInfo: IEquipmentState = {
        refrigerant: cpmFormInput.RefrigerantId,
        designTd: cpmFormInput.BoxTemperature - cpmFormInput.EvaporatorTemperature,
        suctionLineLoss: cpmFormInput.SuctionLineLoss,
        condensingUnitAmbientTemperature: cpmFormInput.AmbientTemperature,

        uid: "",
        isEditing: false,
        isCUAmbientTempVisible: false,
        isCondensingTempVisible: false,
        equipmentTypes: [],
        refrigerants: [],
        siteLineFrequencies: [],
        equipmentType: "",
        interiorTemperature: cpmFormInput.BoxTemperature,
        safetyFactor: 0,
        systemRunTime: 0,
        totalHourlyLoad: 0,
        hourlyLoadRequired: 0,
        capacityRequired: 0,
        siteLineFrequency: 0,
        condensingTemperature: cpmFormInput.CondensingTemperature,
        tdPlus: 0,
        tdMinus: 0,
        condenserQuantity: 0,
        evaporatorQuantity: 0,
        condenserUnitCount: 0,
        evaporatorUnitCount: 0,
        defaultValues: null,
        isCondenserPrimary: null,
        condenserModelIdFilter: null,
        condenserClass9IdFilter: null,
        evaporatorModelIdFilter: null,
        evaporatorClass9IdFilter: null,
        selectedCondenserClass9Id: null,
        selectedCondenserCapacityPerUnit: null,
        selectedCondenserOptionList: [],
        selectedCondenserUnit: null,
        selectedEvaporatorClass9Id: null,
        selectedEvaporatorCapacityPerUnit: null,
        selectedEvaporatorOptionList: [],
        selectedEvaporator: null,
        systemBalanced: false,
        sstBalance: undefined,
        tdBalance: undefined,
        totalSystemCapacity: undefined,
        modalState: false,
        divisionId: 0,
        boxApplicationId: 0,
        boxApplicationName: "",
        equipmentOnly: false,
        selectedCondenserAttributes: StorageFacade.EquipmentToPrint_Attributes,
        selectedEvaporatorAttributes: StorageFacade.EquipmentToPrint_Attributes,
        isPrimaryReloaded: false,
        isMatchedReloaded: false
    };

    return (
        <>
            <nav className="level hidden-for-print">
                <div className="level-left">
                    <div className="level-item">
                        <button
                            type="button"
                            className="button is-link"
                            onClick={() => window.print()}
                        >
                            Print to PDF
						</button>
                    </div>
                    <div className="level-item">
                        <button type="button" className="button is-link" onClick={() => { window.close(); }}>Close Preview</button>
                    </div>
                    <div className="level-item" style={{ fontSize: "30px" }}>
                        {
                            ((exportingPageNumber > 0) && (
                                <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
									<b>{`Exporting page ${exportingPageNumber} / ${totalPageNumber} ...`}</b>
                                </>
                            ))
                        }
                        {
                            ((exportingPageNumber < 0 && !isFromLegacy) && (
                                <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
									<b>Please go to the Downloads folder to find your certified print.</b>
                                </>
                            ))
                        }
                    </div>
                </div>
            </nav>            
            {equipmentToPrint && (
                <>               
                    <br/>     
                    <div className="printarea">
                        <div id="singleCPMSystem">
                            <div className="printpage">
                            <ModelInfo
                                sectionId="ModelInfo"
                                divisionId={divisionId}
                                jobName={StorageFacade.jobName}
                                customerName={StorageFacade.customerInfo?.Contact ?? ""}
                                myName={StorageFacade.CPMSubmittedBy}
                                equipmentInfo={equipmentInfo}
                                isCondensingUnit={false}
                                selectedUnitItem={equipmentToPrint}
                                selectedOptionList={options}
                                excludedOptionIds={null}
                                selectedUnitAttributes={equipmentInfo?.selectedCondenserAttributes}
                                isPrintSingleEquipment={true}
                                quoteFormInput={cpmQuoteFormInput}
                                itemNumber={StorageFacade.CPMFormInput?.ItemNumber??0 }
                            />
                            </div>
                            <br />
                            <ModelDrawing
                                sectionId="ModelDrawing"
                                divisionId={divisionId}
                                jobName={StorageFacade.jobName}
                                customerName={StorageFacade.customerInfo?.Contact ?? ""}
                                myName={StorageFacade.CPMSubmittedBy}
                                interiorTemperature={systemState.formState.actualValues.interiorTemperature}
                                equipmentInfo={equipmentInfo}
                                isCondensingUnit={false}
                                selectedUnitItem={equipmentToPrint}
                                selectedOptionList={options}
                                selectedUnitAttributes={equipmentInfo?.selectedCondenserAttributes}
                                isPrintSingleEquipment={true}
                                capacityHasError={capacityError}
                                quoteFormInput={cpmQuoteFormInput}
                                itemNumber={StorageFacade.CPMFormInput?.ItemNumber??0}
                                isForACCD={false}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PrintSingleReport;
