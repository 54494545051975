import React, { useEffect } from 'react';

function KeepSessionAlive() {
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetch('/account/keepalive', {
                credentials: 'include', // Ensure cookies are included with the request
                redirect: 'manual', // Prevent automatic following of redirects
                method: 'GET'
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Session keep alive failed');
                }
                return response.text();
            })
            .then(data => {
                console.log(data); // Log the server response
            })
            .catch(error => {
                console.error('Error keeping session alive:', error);
                // Optionally handle session expiration (e.g., redirect to login)
            });
        }, 1200000); // Every 20 minutes

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);

    return null;
}

export default KeepSessionAlive;
