/* eslint-disable lines-between-class-members */
/* eslint-disable camelcase */

import MeasurementType from "../../enums/MeasurementType";
import StorageFacade from "../../services/StorageFacade";
import { getNumericValue, hasValue, localeString, measure, roundOff } from "../../types/measure";

class UnitOfMeasure {

	public static KilowattsDecimalPlaces: number = 3;

	// Rating
	private static BTUH_to_Kw: number = 0.0002930711;
	private static Kw_to_BTUH: number = 1 / 0.0002930711;
	private static BTUH_to_W: number = 0.2930711;
	private static W_to_BTUH: number = 1 / 0.2930711;
	private static BTUH_to_Kc: number = 0.2519958;
	private static Kc_to_BTUH: number = 1 / 0.2519958;
	private static HP_to_W: number = 746.0;
	private static W_to_HP: number = 1 / 746.0;

	// Electrical Power
	private static BTUH_to_HP: number = (0.2930711 * 1) / 746;
	private static HP_to_BTUH: number = 746 / 0.2930711;

	// Weight
	private static LB_to_KG: number = 0.453592;
	private static KG_to_LB: number = 1 / 0.453592;

	// Dimension
	private static M_to_Ft: number = 3.28084;
	private static Ft_to_M: number = 1 / 3.28084;
	private static M_to_In: number = 39.3701;
	private static In_to_M: number = 1 / 39.3701;
	private static Ft_to_In: number = 12.0;
	private static In_to_Ft: number = 1 / 12.0;
	private static SqM_to_SqFt: number = 10.764;
	private static SqFt_to_SqM: number = 1 / 10.764;
	private static CuM_to_CuFt: number = 35.3147;
	private static CuFt_to_CuM: number = 1 / 35.3147;

	// Air Flow
	private static CFM_to_CMPH: number = 1.69901;
	private static CMPH_to_CFM: number = 1 / 1.69901;
	private static CFM_to_LPS: number = 0.4719474;
	private static LPS_to_CFM: number = 1 / 0.4719474;
	private static CFM_to_CMPS: number = 0.4719474 / 1000;
	private static CMPS_to_CFM: number = 1 / (0.4719474 / 1000);

	public static TDatRH(rh: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(39.5 - rh * 0.35, decimalPlaces));
	}

	public static RHatTD(td: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff((39.5 - td) / 0.35, decimalPlaces));
	}

	public static TemperatureFtoC(f: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(((f - 32) * 5) / 9, decimalPlaces));
	}

	public static TemperatureCtoF(c: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff((c * 9) / 5 + 32, decimalPlaces));
	}

	public static TemperatureRelativeFtoC(
		f: number,
		decimalPlaces?: number
	): number {
		return getNumericValue(roundOff((f * 5) / 9, decimalPlaces));
	}

	public static TemperatureRelativeCtoF(
		c: number,
		decimalPlaces?: number
	): number {
		return getNumericValue(roundOff((c * 9) / 5, decimalPlaces));
	}

	public static RatingKilowattsToBTUH(
		kw: number,
		decimalPlaces?: number
	): number {
		return getNumericValue(roundOff(kw * this.Kw_to_BTUH, decimalPlaces));
	}

	public static RatingBTUHtoKilowatts(
		b: number,
		decimalPlaces?: number
	): number {
		return getNumericValue(roundOff(b * this.BTUH_to_Kw, decimalPlaces));
	}

	public static RatingWattsToBTUH(w: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(w * this.W_to_BTUH, decimalPlaces));
	}

	public static RatingBTUHtoWatts(b: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(b * this.BTUH_to_W, decimalPlaces));
	}

	public static BTHUToHP(b: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(b * this.BTUH_to_HP, decimalPlaces));
	}

	public static HPToBTHU(h: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(h * this.HP_to_BTUH, decimalPlaces));
	}

	public static KgToLb(kg: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(kg * this.KG_to_LB, decimalPlaces));
	}

	public static LbToKg(lb: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(lb * this.LB_to_KG, decimalPlaces));
	}

	public static HPtoWatts(h: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(h * this.HP_to_W, decimalPlaces));
	}

	public static WattstoHp(w: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(w * this.W_to_HP, decimalPlaces));
	}

	public static MtoFt(m: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(m * this.M_to_Ft, decimalPlaces));
	}

	public static FttoM(ft: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(ft * this.Ft_to_M, decimalPlaces));
	}

	public static MtoIn(m: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(m * this.M_to_In, decimalPlaces));
	}

	public static IntoM(_in: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(_in * this.In_to_M, decimalPlaces));
	}

	public static FttoIn(ft: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(ft * this.Ft_to_In, decimalPlaces));
	}

	public static IntoFt(_in: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(_in * this.In_to_Ft, decimalPlaces));
	}

	public static SqMtoSqFt(sqm: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(sqm * this.SqM_to_SqFt, decimalPlaces));
	}

	public static SqFttoSqM(sqft: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(sqft * this.SqFt_to_SqM, decimalPlaces));
	}

	public static CuMtoCuFt(cubicm: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(cubicm * this.CuM_to_CuFt, decimalPlaces));
	}

	public static CuFttoCuM(cubicft: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(cubicft * this.CuFt_to_CuM, decimalPlaces));
	}

	public static CFMtoCMPH(cfm: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(cfm * this.CFM_to_CMPH, decimalPlaces));
	}

	public static CMPHtoCFM(cmph: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(cmph * this.CMPH_to_CFM, decimalPlaces));
	}

	public static CFMtoLPS(cfm: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(cfm * this.CFM_to_LPS, decimalPlaces));
	}

	public static LPStoCFM(lps: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(lps * this.LPS_to_CFM, decimalPlaces));
	}

	public static CFMtoCMPS(cfm: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(cfm * this.CFM_to_CMPS, decimalPlaces));
	}

	public static CMPStoCFM(cmps: number, decimalPlaces?: number): number {
		return getNumericValue(roundOff(cmps * this.CMPS_to_CFM, decimalPlaces));
	}

	public static decimalToFraction(dec: number): string {
		if (dec === undefined) return "";

		const gcd = (a: any, b: any): any => {
			if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.
			return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
		};

		let whole: number = 0;
		if (dec >= 1) whole = Math.floor(dec);

		const fraction: number = dec - whole;
		const len = fraction.toString().length - 2;

		let denominator = 10 ** len;
		let numerator = fraction * denominator;

		const divisor = gcd(numerator, denominator); // Should be 5

		numerator /= divisor; // Should be 687
		denominator /= divisor; // Should be 2000

		if (whole > 0) {
			if (fraction > 0)
				return `${whole} ${Math.floor(numerator)}/${Math.floor(denominator)}`;

			return whole.toString();
		}

		return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
	}

	public static formatFraction(dec: number): string {
		const LargestDenominator: number = 64;

		let Denominator: number;
		let GCD: number;
		let Numerator: number;
		let Remainder: number;
		let TopNumber: number;
		let WholeNumber: number = 0;
		let result: string = "";

		if (dec >= 1) WholeNumber = Math.floor(dec);
		Denominator = LargestDenominator;
		Numerator = Math.floor(Denominator * Math.abs(dec - WholeNumber));

		if (Numerator > 0) {
			GCD = LargestDenominator;
			TopNumber = Numerator;

			do {
				Remainder = GCD % TopNumber;
				GCD = TopNumber;
				TopNumber = Remainder;
			} while (Remainder !== 0);

			Numerator = Math.floor(Numerator / GCD);
			Denominator = Math.floor(Denominator / GCD);
			if (Math.abs(WholeNumber) > 0) {
				result = `${WholeNumber} ${Numerator}/${Denominator}`;
			} else {
				result = `${Numerator}/${Denominator}`;
				if (dec < 0) result = `-${result}`;
			}
		} else result = WholeNumber.toString();

		if (result === "0") return "";

		return result;
	}

	public static numberRound(numberToRound: any, numOfDecimalPlaces: number) {
		if (numberToRound === undefined || Number.isNaN(numberToRound)) {
			return "";
		}
		const result = Math.round(numberToRound * Math.pow(10, numOfDecimalPlaces)) / Math.pow(10, numOfDecimalPlaces);

		if (!result.toString().includes(".")) {
			let decPlaces = ".";
			for (let i = 1; i <= numOfDecimalPlaces; i += 1) {
				decPlaces += "0";
			}

			return result.toString() + decPlaces;
		}

		return result.toString();
	}

	// find the number value and the unit of measure value from the input
	public static processInput(input: string): [string, string] {
		const numbers: string = "-0123456789.,";

		let numberValue: string = "";
		let umValue: string = "";

		for (let i = 0; i < input.length; i += 1) {
			const character = input.charAt(i);
			if (numbers.indexOf(character) >= 0) {
				numberValue += character;
			} else {
				umValue += character;
			}
		}

		return [numberValue, umValue];
	}

	public static allowedCharacter(
		input: string,
		measurementType: MeasurementType
	): boolean {
		const numbers: string = "0123456789.";

		if (numbers.indexOf(input) < 0) {
			switch (measurementType) {
				case MeasurementType.AirFlow:
					return false; // not implemented yet
				case MeasurementType.Dimension:
				case MeasurementType.SmallerDimension:
					if (
						input.toLowerCase() !== "m" &&
						input.toLowerCase() !== "f" &&
						input.toLowerCase() !== "t" &&
						input.toLowerCase() !== "i" &&
						input.toLowerCase() !== "n" &&
						input.toLowerCase() !== "c" &&
						input.toLowerCase() !== "'" &&
						input.toLowerCase() !== '"'
					)
						return false;
					return true;
				case MeasurementType.ElectricPower:
					if (input.toLowerCase() !== "h" && input.toLowerCase() !== "w")
						return false;
					return true;
				case MeasurementType.Rating:
					if (
						input.toLowerCase() !== "b" &&
						input.toLowerCase() !== "w" &&
						input.toLowerCase() !== "k" &&
						input !== "-"
					)
						return false;
					return true;
				case MeasurementType.Temperature:
					if (
						input.toLowerCase() !== "c" &&
						input.toLowerCase() !== "f" &&
						input !== "-"
					)
						return false;
					return true;
				case MeasurementType.PositiveTemperature:
					if (input.toLowerCase() !== "c" && input.toLowerCase() !== "f")
						return false;
					return true;
				case MeasurementType.TemperatureRelative:
					if (input.toLowerCase() !== "c" && input.toLowerCase() !== "f")
						return false;
					return true;
				case MeasurementType.Weight:
					if (input.toLowerCase() !== "l" && input.toLowerCase() !== "k")
						return false;
					return true;
				case MeasurementType.PositiveDecimal:
					return false;
				case MeasurementType.Area:
				case MeasurementType.Volume:
					if (
						input.toLowerCase() !== "m" &&
						input.toLowerCase() !== "f" &&
						input.toLowerCase() !== "t" &&
						input.toLowerCase() !== "'" &&
						input.toLowerCase() !== '"'
					)
						return false;
					return true;
				default:
					break;
			}
		}

		if (numbers.indexOf(input) >= 0) {
			return true;
		}

		return false;
	}

	public static convertValueWithUnit(
		valueWithUnit: string,
		usesMetric: boolean,
		measurementType: MeasurementType
	): measure {
		const [numberValue, umValue] = this.processInput(valueWithUnit);

		if (!numberValue || numberValue.length <= 0) return undefined;
		if (!umValue || umValue.length <= 0) return +numberValue;

		switch (measurementType) {
			case MeasurementType.Temperature:
			case MeasurementType.PositiveTemperature: {
				// Metric default unit for temperature is Celsius.
				// Imperial default unit for temperature is Fahrenheit.

				// Fahrenheit
				if (umValue.trim().toLowerCase() === "f") {
					// Fahrenheit to Celsius
					if (usesMetric) {
						return UnitOfMeasure.TemperatureFtoC(+numberValue);
					}
					// Fahrenheit to Fahrenheit
					return +numberValue;
				}
				// Celsius
				if (umValue.trim().toLowerCase() === "c") {
					// Celsius to Celsius
					if (usesMetric) {
						return +numberValue;
					}
					// Celsius to Fahrenheit
					return UnitOfMeasure.TemperatureCtoF(+numberValue);
				}
				break;
			}
			case MeasurementType.TemperatureRelative: {
				// Metric default unit for temperature is Celsius.
				// Imperial default unit for temperature is Fahrenheit.

				// Fahrenheit
				if (umValue.trim().toLowerCase() === "f") {
					// Fahrenheit to Celsius
					if (usesMetric) {
						return UnitOfMeasure.TemperatureRelativeFtoC(+numberValue);
					}
					// Fahrenheit to Fahrenheit
					return +numberValue;
				}
				// Celsius
				if (umValue.trim().toLowerCase() === "c") {
					// Celsius to Celsius
					if (usesMetric) {
						return +numberValue;
					}
					// Celsius to Fahrenheit
					return UnitOfMeasure.TemperatureRelativeCtoF(+numberValue);
				}
				break;
			}
			case MeasurementType.Rating: {
				// Metric default unit for rating is Kilowatts.
				// Imperial default unit for rating is BTUH.

				// BTUH
				if (umValue.charAt(0).toLowerCase() === "b") {
					// BTUH to Kilowatts
					if (usesMetric) {
						return UnitOfMeasure.RatingBTUHtoWatts(+numberValue) / 1000.0;
					}
					// BTUH to BTUH
					return +numberValue;
				}
				// Watts
				if (umValue.charAt(0).toLowerCase() === "w") {
					// Watts to Kilowatts
					if (usesMetric) {
						return +numberValue / 1000.0;
					}
					// Watts to BTUH
					return UnitOfMeasure.RatingWattsToBTUH(+numberValue);
				}
				// Kilowatts
				if (umValue.charAt(0).toLowerCase() === "k") {
					// Kilowatts to Kilowatts
					if (usesMetric)
					{
						return +numberValue;
					}
					// Kilowatts to BTUH
					return UnitOfMeasure.RatingWattsToBTUH(+numberValue * 1000);
				}
				break;
			}
			case MeasurementType.Dimension: {
				// Metric default unit for dimension is Meters.
				// Imperial default unit for dimension is Feet.

				// Feet
				if (
					umValue.trim().toLowerCase() === "f" ||
					umValue.trim().toLowerCase() === "ft" ||
					umValue.trim().toLowerCase() === "'"
				) {
					// Feet to Meters
					if (usesMetric) {
						return UnitOfMeasure.FttoM(+numberValue);
					}
					// Feet to Feet
					return +numberValue;
				}
				// Centimeters
				if (umValue.trim().toLowerCase() === "cm") {
					// Centimeters to Meters
					if (usesMetric) {
						return +numberValue / 100.0;
					}
					// Centimeters to Feet
					return UnitOfMeasure.MtoFt(+numberValue / 100.0);
				}
				// Millimeters
				if (umValue.trim().toLowerCase() === "mm") {
					// Millimeters to Meters
					if (usesMetric) {
						return +numberValue / 1000.0;
					}
					// Millimeters to Feet
					return UnitOfMeasure.MtoFt(+numberValue / 1000.0);
				}
				// Meters
				if (umValue.trim().toLowerCase() === "m") {
					// Meters to Meters
					if (usesMetric) {
						return +numberValue;
					}
					// Meters to Feet
					return UnitOfMeasure.MtoFt(+numberValue);
				}
				// Inches
				if (
					umValue.trim().toLowerCase() === "i" ||
					umValue.trim().toLowerCase() === "in" ||
					umValue.trim().toLowerCase() === '"'
				) {
					// Inches to Meters
					if (usesMetric) {
						return UnitOfMeasure.IntoM(+numberValue);
					}
					// Inches to Feet
					return UnitOfMeasure.IntoFt(+numberValue);
				}
				break;
			}
			case MeasurementType.SmallerDimension: {
				// Metric default unit for smaller dimension is Millimeters.
				// Imperial default unit for smaller dimension is Inches.

				// Feet
				if (
					umValue.trim().toLowerCase() === "f" ||
					umValue.trim().toLowerCase() === "ft" ||
					umValue.trim().toLowerCase() === "'"
				) {
					// Feet to Millimeters
					if (usesMetric) {
						return UnitOfMeasure.FttoM(+numberValue) * 1000.0;
					}
					// Feet to Inches
					return UnitOfMeasure.FttoIn(+numberValue);
				}
				// Centimeters
				if (umValue.trim().toLowerCase() === "cm") {
					// Centimeters to Millimeters
					if (usesMetric) {
						return +numberValue * 10.0;
					}
					// Centimeters to Inches
					return UnitOfMeasure.MtoIn(+numberValue / 100.0);
				}
				// Millimeters
				if (umValue.trim().toLowerCase() === "mm") {
					// Millimeters to Millimeters
					if (usesMetric) {
						return +numberValue;
					}
					// Millimeters to Inches
					return UnitOfMeasure.MtoIn(+numberValue / 1000.0);
				}
				// Meters
				if (umValue.trim().toLowerCase() === "m") {
					// Meters to Millimeters
					if (usesMetric) {
						return +numberValue * 1000.0;
					}
					// Meters to Inches
					return UnitOfMeasure.MtoIn(+numberValue);
				}
				// Inches
				if (
					umValue.trim().toLowerCase() === "i" ||
					umValue.trim().toLowerCase() === "in" ||
					umValue.trim().toLowerCase() === '"'
				) {
					// Inches to Millimeters
					if (usesMetric) {
						return UnitOfMeasure.IntoM(+numberValue) * 1000.0;
					}
					// Inches to Inches
					return +numberValue;
				}
				break;
			}
			case MeasurementType.Weight: {
				// Metric default unit for weight is Kilograms.
				// Imperial default unit for weight is Pounds.

				// Pounds
				if (umValue.charAt(0).toLowerCase() === "l") {
					// Pounds to Kilograms
					if (usesMetric) {
						return UnitOfMeasure.LbToKg(+numberValue);
					}
					// Pounds to Pounds
					return +numberValue;
				}
				// Kilograms
				if (umValue.charAt(0).toLowerCase() === "k") {
					// Kilograms to Kilograms
					if (usesMetric) {
						return +numberValue;
					}
					// Kilograms to Pounds
					return UnitOfMeasure.KgToLb(+numberValue);
				}
				break;
			}
			case MeasurementType.ElectricPower: {
				// Metric default unit for electric power is Watts.
				// Imperial default unit for electric power is Horsepower.

				// HP
				if (umValue.charAt(0).toLowerCase() === "h") {
					// HP to W
					if (usesMetric) {
						return UnitOfMeasure.HPtoWatts(+numberValue);
					}
					// HP to HP
					return +numberValue;
				}
				// W
				if (umValue.charAt(0).toLowerCase() === "w") {
					// W to W
					if (usesMetric) {
						return +numberValue;
					}
					// W to HP
					return UnitOfMeasure.WattstoHp(+numberValue);
				}
				break;
			}
			case MeasurementType.Area: {
				// Metric default unit for area is Square Meters.
				// Imperial default unit for area is Square Feet.

				// SqFeet
				if (
					umValue.trim().toLowerCase() === "f" ||
					umValue.trim().toLowerCase() === "ft" ||
					umValue.trim().toLowerCase() === "'"
				) {
					// SqFeet to SqMeters
					if (usesMetric) {
						return UnitOfMeasure.SqFttoSqM(+numberValue);
					}
					// SqFeet to SqFeet
					return +numberValue;
				}
				// SqMeters
				if (umValue.trim().toLowerCase() === "m") {
					// SqMeters to SqMeters
					if (usesMetric) {
						return +numberValue;
					}
					// SqMeters to SqFeet
					return UnitOfMeasure.SqMtoSqFt(+numberValue);
				}
				break;
			}
			case MeasurementType.Volume: {
				// Metric default unit for volume is Cubic Meters.
				// Imperial default unit for volume is Cubic Feet.

				// CuFeet
				if (
					umValue.trim().toLowerCase() === "f" ||
					umValue.trim().toLowerCase() === "ft" ||
					umValue.trim().toLowerCase() === "'"
				) {
					// CuFeet to CuMeters
					if (usesMetric) {
						return UnitOfMeasure.CuFttoCuM(+numberValue);
					}
					// CuFeet to CuFeet
					return +numberValue;
				}
				// CuMeters
				if (umValue.trim().toLowerCase() === "m") {
					// CuMeters to CuMeters
					if (usesMetric) {
						return +numberValue;
					}
					// SqMeters to CuFeet
					return UnitOfMeasure.CuMtoCuFt(+numberValue);
				}
				break;
			}
			default:
				return +numberValue;
		}

		return +numberValue;
	}

	public static getDisplayValue(value: measure, decimalPlaces?: number): string | undefined {
		if (!hasValue(value)) {
			return undefined;
		}
		let displayValue = getNumericValue(value).toString();
		if (hasValue(decimalPlaces)) {
			displayValue = localeString(value, getNumericValue(decimalPlaces));
		}
		return displayValue;
	}

	public static getConvertedDisplayValue(
		imperialValue: number,
		measurementType: MeasurementType
	) {
		if (StorageFacade.UsesMetricSystem) {
			return this.convertImperialToMetric(imperialValue, measurementType);
		}
		return imperialValue;;
	}

	public static getMeasureDisplayValue(imperialValue: measure, measurementType: MeasurementType, showLocaleString: boolean, decimalPlaces?: number): string | undefined {
		if (!hasValue(imperialValue)) {
			return undefined;
		}
		let displayValue = this.getConvertedDisplayValue(getNumericValue(imperialValue), measurementType).toString();
		if (hasValue(decimalPlaces)) {
			if (showLocaleString) {
				displayValue = localeString(this.getConvertedDisplayValue(getNumericValue(imperialValue), measurementType), getNumericValue(decimalPlaces));
			}
			else {
				const roundedOffValue = roundOff(this.getConvertedDisplayValue(getNumericValue(imperialValue), measurementType), getNumericValue(decimalPlaces));	
				displayValue = hasValue(roundedOffValue) ? getNumericValue(roundedOffValue).toString() : "";
			}
		}
		return displayValue;
	}

	public static getTemperatureDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.Temperature, showLocaleString, decimalPlaces);
	}

	public static getTemperatureRelativeDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.TemperatureRelative, showLocaleString, decimalPlaces);
	}

	public static getRatingDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		if (hasValue(decimalPlaces)) {
			if (StorageFacade.UsesMetricSystem) {
				decimalPlaces = getNumericValue(decimalPlaces) + this.KilowattsDecimalPlaces;
			}
		}
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.Rating, showLocaleString, decimalPlaces);
	}

	public static getAirFlowDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		const displayValue = this.getMeasureDisplayValue(imperialValue, MeasurementType.AirFlow, showLocaleString, decimalPlaces);
		if (displayValue === 'NaN') {
			return String(imperialValue);
		}
		return displayValue;
	}

	public static getDimensionDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.Dimension, showLocaleString, decimalPlaces);
	}

	public static getSmallerDimensionDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.SmallerDimension, showLocaleString, decimalPlaces);
	}

	public static getWeightDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.Weight, showLocaleString, decimalPlaces);
	}

	public static getElectricPowerDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.ElectricPower, showLocaleString, decimalPlaces);
	}

	public static getPercentDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.Percent, showLocaleString, decimalPlaces);
	}

	public static getAreaDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.Area, showLocaleString, decimalPlaces);
	}

	public static getVolumeDisplayValue(imperialValue: measure, decimalPlaces?: number, showLocaleString: boolean = true): string | undefined {
		return this.getMeasureDisplayValue(imperialValue, MeasurementType.Volume, showLocaleString, decimalPlaces);
	}

	public static convertMetricToImperial(
		metricValue: number,
		measurementType: MeasurementType
	): number {
		switch (measurementType) {
			case MeasurementType.Temperature:
			case MeasurementType.PositiveTemperature: {
				// Metric (Celsius) to Imperial (Fahrenheit)
				return UnitOfMeasure.TemperatureCtoF(metricValue);
			}
			case MeasurementType.TemperatureRelative: {
				// Metric (Celsius) to Imperial (Fahrenheit)
				return UnitOfMeasure.TemperatureRelativeCtoF(metricValue);
			}
			case MeasurementType.Rating: {
				// Metric (Kilowatts) to Imperial (BTUH)
				return UnitOfMeasure.RatingWattsToBTUH(metricValue * 1000.0); 
			}
			case MeasurementType.Dimension: {
				// Metric (Meters) to Imperial (Feet)
				return UnitOfMeasure.MtoFt(metricValue);
			}
			case MeasurementType.SmallerDimension: {
				// Metric (Millimeters) to Imperial (Inches)
				return UnitOfMeasure.MtoIn(metricValue / 1000.0);
			}
			case MeasurementType.Weight: {
				// Metric (Kilograms) to Imperial (Pounds)
				return UnitOfMeasure.KgToLb(metricValue);
			}
			case MeasurementType.ElectricPower: {
				// Metric (Watts) to Imperial (Horsepower)
				return UnitOfMeasure.WattstoHp(metricValue);
			}
			case MeasurementType.AirFlow: {
				// Metric (Cubic Meter Per Hour) to Imperial (Cubic Feet Per Minute)
				return UnitOfMeasure.CMPHtoCFM(metricValue);
			}
			case MeasurementType.Area: {
				// Metric (Square Meters) to Imperial (Square Feet)
				return UnitOfMeasure.SqMtoSqFt(metricValue);
			}
			case MeasurementType.Volume: {
				// Metric (Cubic Meters) to Imperial (Cubic Feet)
				return UnitOfMeasure.CuMtoCuFt(metricValue);
			}
			default:
				return metricValue;
		}
	}

	public static convertImperialToMetric(
		imperialValue: number,
		measurementType: MeasurementType
	): number {
		switch (measurementType) {
			case MeasurementType.Temperature:
			case MeasurementType.PositiveTemperature: {
				// Imperial (Fahrenheit) to Metric (Celsius)
				return UnitOfMeasure.TemperatureFtoC(imperialValue);
			}
			case MeasurementType.TemperatureRelative: {
				// Imperial (Fahrenheit) to Metric (Celsius)
				return UnitOfMeasure.TemperatureRelativeFtoC(imperialValue);
			}
			case MeasurementType.Rating: {
				// Imperial (BTUH) to Metric (Kilowatts)
				return UnitOfMeasure.RatingBTUHtoWatts(imperialValue) / 1000.0;
			}
			case MeasurementType.Dimension: {
				// Imperial (Feet) to Metric (Meters)
				return UnitOfMeasure.FttoM(imperialValue);
			}
			case MeasurementType.SmallerDimension: {
				// Imperial (Inches) to Metric (Millimeters)
				return UnitOfMeasure.IntoM(imperialValue) * 1000.0;
			}
			case MeasurementType.Weight: {
				// Imperial (Pounds) to Metric (Kilograms)
				return UnitOfMeasure.LbToKg(imperialValue);
			}
			case MeasurementType.ElectricPower: {
				// Imperial (Horsepower) to Metric (Watts)
				return UnitOfMeasure.HPtoWatts(imperialValue);
			}
			case MeasurementType.AirFlow: {
				// Imperial (Cubic Feet Per Minute) to Metric (Cubic Meter Per Hour)
				return UnitOfMeasure.CFMtoCMPH(imperialValue);
			}
			case MeasurementType.Area: {
				// Imperial (Square Feet) to Metric (Square Meters)
				return UnitOfMeasure.SqFttoSqM(imperialValue);
			}
			case MeasurementType.Volume: {
				// Imperial (Cubic Feet) to Metric (Cubic Meters)
				return UnitOfMeasure.CuFttoCuM(imperialValue);
			}
			default:
				return imperialValue;
		}
	}

	public static getUnitLabel(measurementType: MeasurementType) {
		const usesMetricSystem = StorageFacade.UsesMetricSystem;

		switch (measurementType) {
			case MeasurementType.Temperature:
			case MeasurementType.PositiveTemperature:
			case MeasurementType.TemperatureRelative: {
				if (usesMetricSystem) {
					return `${String.fromCharCode(176)}C`;
				}
				return `${String.fromCharCode(176)}F`;
			}
			case MeasurementType.Rating: {
				if (usesMetricSystem) {
					return "kW";
				}
				return "BTUH";
			}
			case MeasurementType.AirFlow: {
				if (usesMetricSystem) {
					return `m${String.fromCharCode(179)}/h`;
				}
				return "CFM";
			}
			case MeasurementType.Dimension: {
				if (usesMetricSystem) {
					return "m";
				}
				return "ft";
			}
			case MeasurementType.SmallerDimension: {
				if (usesMetricSystem) {
					return "mm";
				}
				return "in";
			}
			case MeasurementType.Weight: {
				if (usesMetricSystem) {
					return "kg";
				}
				return "lb";
			}
			case MeasurementType.ElectricPower: {
				if (usesMetricSystem) {
					return "W";
				}
				return "HP";
			}
			case MeasurementType.Percent: {
				return "%";
			}
			case MeasurementType.Area: {
				if (usesMetricSystem) {
					return `m${String.fromCharCode(178)}`;
				}
				return `ft${String.fromCharCode(178)}`;
			}
			case MeasurementType.Volume: {
				if (usesMetricSystem) {
					return `m${String.fromCharCode(179)}`;
				}
				return `ft${String.fromCharCode(179)}`;
			}
			default:
				return "";
		}
	}

	public static getTemperatureUnitLabel() {
		return this.getUnitLabel(MeasurementType.Temperature);
	}

	public static getRatingUnitLabel() {
		return this.getUnitLabel(MeasurementType.Rating);
	}

	public static getAirFlowUnitLabel() {
		return this.getUnitLabel(MeasurementType.AirFlow);
	}

	public static getDimensionUnitLabel() {
		return this.getUnitLabel(MeasurementType.Dimension);
	}

	public static getSmallerDimensionUnitLabel() {
		return this.getUnitLabel(MeasurementType.SmallerDimension);
	}

	public static getWeightUnitLabel() {
		return this.getUnitLabel(MeasurementType.Weight);
	}

	public static getElectricPowerUnitLabel() {
		return this.getUnitLabel(MeasurementType.ElectricPower);
	}

	public static getPercentUnitLabel() {
		return this.getUnitLabel(MeasurementType.Percent);
	}

	public static getAreaUnitLabel() {
		return this.getUnitLabel(MeasurementType.Area);
	}

	public static getVolumeUnitLabel() {
		return this.getUnitLabel(MeasurementType.Volume);
	}
}

export default UnitOfMeasure;
